import axios from "axios"
import { config } from "../../config"

const appsumoAuth = (code) => {
    return new Promise((resolve, reject) => {
        axios.post(`${config.ERISED_BACKEND_URL}/appsumo/auth`, {code: code})
        .then(res => {
            resolve(res?.data)
        })
        .catch(() => {
            resolve(false);
        })
    })
}


export {
    appsumoAuth
};