import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Alert_, Main_Loader, PageAlwaysVisible, Single_ } from 'monica-alexandria';
import { useTranslation } from 'react-i18next';
import q from 'query-string';
import { appsumoAuth } from '../../services/api/Appsumo';

export default function AUTHENTICATE_APPSUMO() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const params = q.parse(location.search); // Extract query parameters
    const appsumoCode = params?.code || ''; // Get the AppSumo `code` from the URL

    useEffect(() => {
        if (appsumoCode) {
            // Call the backend API to authenticate
            appsumoAuth(appsumoCode)
                .then((response) => {
                    if (response) {
                        console.log('Authentication successful:', response);
                        // Handle successful authentication, e.g., save token or redirect
                        navigate('/dashboard'); // Redirect to the dashboard
                    } else {
                        console.error('Authentication failed');
                        // Handle failure, show an alert or error message
                        alert(t('Authentication failed. Please try again.'));
                    }
                })
                .catch((err) => {
                    console.error('Error in authentication process:', err);
                    alert(t('Something went wrong. Please try again.'));
                });
        } else {
            console.warn('No code found in URL');
        }
    }, [appsumoCode, navigate, t]);
    

    return (
        <Single_
            backgroundImage={"https://genius1071.friktoriaservers.net/doitforme/morning/app/griphook/NoAccess.svg"}
            // button={t("Back to your account")}
            // onClick= {()=>window.location.assign(`${clients[authClient]}/${lang}`)}
            text={t("We are authorizing your appsumo license. This may take a minute. Please don't leave this page.")}
            title={t("Welcome!")}
        />
    );
}
