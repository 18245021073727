//         ,''',
//        .' ., .',                                  ../'''',
//        .'. %%, %.',                            .,/' .,%   :
//       .'.% %%%,`%%%'.    .....,,,,,,.....   .,%%% .,%%'. .'
//       : %%% %%%%%%',:%%>>%>' .,>>%>>%>%>>%%>,.   `%%%',% :                         ||```````````````````````````||
//       : %%%%%%%'.,>>>%'   .,%>%>%'.,>%>%' . `%>>>,. `%%%:'                         ||                           ||
//       ` %%%%'.,>>%'  .,%>>%>%' .,%>%>%' .>>%,. `%%>>,. `%                          ||      ΠΡΟΣΟΧΗ: ΝΕΥΡΙΑΖΕΙ   ||
//        `%'.,>>>%'.,%%%%%%%' .,%%>%%>%' >>%%>%>>%.`%% %% `,                         ||     ΑΝ ΑΚΟΥΜΠΗΣΕΤΕ ΤΟΝ    ||
//        ,`%% %%>>>%%%>>%%>%%>>%>>%>%%%  %%>%%>%%>>%>%%%' % %,                       ||     ΚΩΔΙΚΑ Ή ΤΟ ΦΑΙ ΤΟΥ   ||
//      ,%>%'.>>%>%'%>>%%>%%%%>%'                 `%>%>>%%.`%>>%.                     ||                           ||
//    ,%%>' .>%>%'.%>%>>%%%>>%' ,%%>>%%>%>>%>>%>%%,.`%%%>%%. `%>%.                    ===============================
//   ` ,%' .>%%%'.%>%>>%' .,%%%%%%%%'          `%%%%%%.`%%>%% .%%>
//    .%>% .%%>' :%>>%%'.,%%%%%%%%%'.%%%%%' `%%%%.`%%%%%.%%%%> %%>%.
//   ,%>%' >>%%  >%' `%%%%'     `%%%%%%%'.,>,. `%%%%'     `%%%>>%%>%
//  .%%>%' .%%>'  %>>%, %% oO ~ Oo %%%>>'.>>>>>>. `% oO ~ Oo'.%%%'%>%,
//   %>'%> .%>%>%  %%>%%%'  `OoooO'.%%>>'.>>>%>>%>>.`%`OoooO'.%%>% '%>%
//   %',%' %>%>%'  %>%>%>% .%,>,>,   `>'.>>%>%%>>>%>.`%,>,>' %%%%> .>%>,
//   ` %>% `%>>%%. `%%% %' >%%%%%%>,  ' >>%>>%%%>%>>> >>%%' ,%%>%'.%%>>%.
//   .%%'  %%%%>%.   `>%%. %>%%>>>%.>> >>>%>%%%%>%>>.>>>'.>%>%>' %>>%>%%
//   `.%%  `%>>%%>    %%>%  %>>>%%%>>'.>%>>>>%%%>>%>>.>',%>>%'  ,>%'>% '
//    %>'  %%%%%%'    `%%'  %%%%%> >' >>>>%>>%%>>%>>%> %%>%>' .%>%% .%%
//   %>%>, %>%%>>%%,  %>%>% `%%  %>>  >>>%>>>%%>>>>%>>  %%>>,%>%%'.%>%,
//    %>%>%%, `%>%%>%>%, %>%%> ,%>%>>>.>>`.,.  `"   ..'>.%. % %>%>%'.%>%%;
//    %'`%%>%  %%>%%  %>% %'.>%>>%>%%>>%::.  `,   /' ,%>>>%>. >%>%'.%>%'%'
//    ` .%>%'  >%%% %>%%'.>%>%;''.,>>%%>%%::.  ..'.,%>>%>%>,`%  %'.>%%' '
//    %>%>%% `%>  >%%'.%%>%>>%>%>%>>>%>%>>%,,::,%>>%%>%>>%>%% `>>%>'
//     %'`%%>%>>%  %>'.%>>%>%>>;'' ..,,%>%>%%/::%>%%>>%%,,.``% .%>%%
// `    `%>%>>%%' %>%%>>%>>%>%>%>%%>%/'       `%>%%>%>>%%% ' .%'
//       %'  `%>% `%>%%;'' .,>>%>%/',;;;;;,;;;;,`%>%>%,`%'   '
//        `    `  ` `%>%%%>%%>%%;/ @a;;;;;;;;;;;a@  >%>%%'                  //////////////////////////////////////////////
//                   `/////////';, `@a@@a@@a@@aa@',;`//'                    // Περιγραφή: ΡΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ-
//                       `//////.;;,,............,,;;//'                    // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                         `////;;;;;;;;;;;;;;;;;/'                         // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                            `/////////////////'                           // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑ
//                                                                          // ΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΑΡΡ
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { ThemeContext, LangContext, MenuContext, Page, RegionContext, Theme, ThemeDark, ThemeLight, _Nav, AlertContext } from "monica-alexandria";
import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GuardedRoute } from "../services/authentication/Guard";
import { Protector } from "../services/authentication/Protector";
import LoginTunnel from "../services/authentication/loginTunnel";

import Navbar_ from '../components/navigation/Navbar_';

import TRANSLATIONS from "../admin/views/translations/TRANSLATIONS";
import PANEL from "../admin/views/panel/PANEL";
import USERS from "../admin/views/users/USERS";
import AUTHENTICATE from "../pages/authenticate/AUTHENTICATE";
import NO_ACCESS from "../admin/views/noAccess/NO_ACCESS";
import USER_PANEL from "../pages/userPanel/USER_PANEL";
import NOT_FOUND from "../pages/notFound/NOT_FOUND";
import ADD_PASS from "../pages/addPass/ADD_PASS";
import SETTINGS from "../pages/settings/SETTINGS";
import ACCOUNT from "../pages/account/ACCOUNT";
import SECURITY from "../pages/security/SECURITY";
import PREFERENCES from "../pages/preferences/PREFERENCES";
import SERVICE_UNAVAILABLE from "../pages/serviceUnavailable/SERVICE_UNAVAILABLE";
import RESET_PASSWORD from "../pages/resetPassword/RESET_PASSWORD";
import NEW_PASSWORD from "../pages/newPassword/NEW_PASSWORD";
import SUCCESS_RESET from "../pages/successReset/SUCCESS_RESET";
import USER from '../admin/views/user/USER';
import { init, reconstructUrl } from "../helpers/initMenuOptions";
import { useSelector } from "react-redux";
import VERIFY_EMAIL from "../pages/verifyEmail/VERIFY_EMAIL";
import CHANGE_USERNAME from "../pages/changeUsername/CHANGE_USERNAME";
import REQUESTS from "../admin/views/requests/REQUESTS";
import CHANGE_USERNAME_SUCCESS from "../pages/changeUsernameSuccess/CHANGE_USERNAME_SUCCESS";
import i18n from "../localization/i18next";
import AUTHENTICATE_APPSUMO from "../pages/authenticate/AUTHENTICATE_APPSUMO";

export default function Erised() {

    const user = useSelector(state => state.user.user)

    const navigate = useNavigate()
    
    const [theme, setTheme] = useState(); 
    const [language, setLanguage] = useState(); 
    const [region, setRegion] = useState(); 
    const [activeMenu, setActiveMenu] = useState('Main'); 
    const [alerts, setAlerts] = useState([]);

    useEffect(() => {
        if ( i18n?.language && reconstructUrl(language) ) navigate(reconstructUrl(language))
    }, [language])


    useEffect(() => {
        init(user, setTheme, setLanguage, setRegion);
    }, [user]);
  
    // console.log(user)
    return theme && language && region ? (
        <LangContext.Provider value={{language, setLanguage}}>
            <RegionContext.Provider value={{region, setRegion}}>    
                <ThemeContext.Provider value={{theme, setTheme}}>
                    <ThemeProvider theme={theme === 'dark' ? ThemeDark : ThemeLight}>
                        <AlertContext.Provider value={{alerts, setAlerts}}>
                        {/* <MQTTContext.Provider value={{client, setClient}}> */}
                            <Theme>
                                <Page>
                                    <MenuContext.Provider
                                        value={{ activeMenu, setActiveMenu }}
                                    >
                                        <GuardedRoute verified={true} >
                                            <Navbar_ />
                                        </GuardedRoute>
                                    </MenuContext.Provider>
                                    <Routes>
                                        {/* UNPROTECTED */}

                                        {/* PROTECTED */}
                                        <Route path="/" 
                                            element={<Navigate to={{
                                            pathname: `/${language}/`}}
                                            />}
                                        />
                                        <Route path="/" element={<Protector><USER_PANEL/></Protector>} />
                                        <Route path="/:lang" element={<Protector><USER_PANEL/></Protector>} />
                                        <Route path="/:lang/panel" element={<Protector><USER_PANEL/></Protector>} />
                                        <Route path="/:lang/login/:client/" element={<GuardedRoute><LoginTunnel/></GuardedRoute>} />
                                        <Route path="/:lang/auth/" element={<GuardedRoute verified={true}><AUTHENTICATE/></GuardedRoute>} />
                                        <Route path="/:lang/auth-appsummo/" element={<GuardedRoute verified={true}><AUTHENTICATE_APPSUMO/></GuardedRoute>} />

                                        <Route path="/:lang/auth/:email/" element={<GuardedRoute verified={true}><AUTHENTICATE/></GuardedRoute>} />
                                        <Route path="/:lang/add-password" element={<Protector><ADD_PASS/></Protector>} />
                                        <Route path="/:lang/verify-account" element={<Protector verified={true}><VERIFY_EMAIL/></Protector>} />
                                        <Route path="/:lang/settings" element={<Protector><SETTINGS/></Protector>} />
                                        <Route path="/:lang/settings/my-account/" element={<Protector><ACCOUNT/></Protector>} />
                                        <Route path="/:lang/settings/privacy-and-security" element={<Protector><SECURITY/></Protector>} />
                                        <Route path="/:lang/settings/preferences" element={<Protector><PREFERENCES/></Protector>} />
                                        <Route path="/:lang/service-unavailable" element={<SERVICE_UNAVAILABLE/>} />
                                        <Route path="/:lang/reset-password" element={<Protector><RESET_PASSWORD/></Protector>} />
                                        <Route path="/:lang/new-password" element={<NEW_PASSWORD/>} />
                                        <Route path="/:lang/success-reset" element={<SUCCESS_RESET/>} />
                                        <Route path="/:lang/change-username" element={ <CHANGE_USERNAME/> } />
                                        <Route path="/:lang/change-username-success" element={ <CHANGE_USERNAME_SUCCESS/> } />
                                        
                                        {/* ADMIN */}
                                        <Route
                                            path="/:lang/akihiko/erised"
                                            element={
                                                <Protector role="Knight">
                                                    <PANEL />
                                                </Protector>
                                            }
                                        />
                                        <Route
                                            path="/:lang/akihiko/erised/translations"
                                            element={
                                                <Protector role="Knight">
                                                    <TRANSLATIONS />
                                                </Protector>
                                            }
                                        />
                                        <Route
                                            path="/:lang/akihiko/erised/users"
                                            element={
                                                <Protector role="Baron">
                                                    <USERS />
                                                </Protector>
                                            }
                                        />
                                        <Route
                                            path="/:lang/akihiko/erised/requests"
                                            element={
                                                <Protector role="Knight">
                                                    <REQUESTS />
                                                </Protector>
                                            }
                                        />
                                        <Route
                                            path="/:lang/akihiko/erised/users/:id"
                                            element={
                                                // <Protector role="Baron">
                                                    <USER />
                                                // </Protector>
                                            }
                                        />
                                        {/* MISC */}
                                        <Route
                                            path="/:lang/access-denied"
                                            element={<NO_ACCESS />}
                                        />
                                        <Route
                                            path="*"
                                            element={<NOT_FOUND />}
                                        />
                                    </Routes>
                                </Page>
                            </Theme>
                        </AlertContext.Provider>
                        {/* </MQTTContext.Provider> */}
                    </ThemeProvider>
                </ThemeContext.Provider>
            </RegionContext.Provider>
        </LangContext.Provider> 
    ) : <></>;
}
