import {
    Card,
    CardsForty,
    CardsSixty,
    Focus_Plain,
    On_Click_Card,
    PageAlwaysVisible,
    PageDesktopPadding,
    PageSixty,
    PageTitle,
    PageToolsSixty,
    Section_Title,
    Theme,
    ThemeContext,
    Unlock_Card,
    device,
    logo,
} from 'monica-alexandria';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Aside_ from '../../components/navigation/Aside_';
import i18n from '../../localization/i18next';
import {
    checkAccess,
    get_my_subscriptions,
    has_access_to_tool,
} from '../../services/api/Users';
import store from '../../redux/store';
import matchCategoriesToText from '../../data/matchCategoriesToText.json';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { checkPermissions } from '../../helpers/checkPermissions';
import { AuthContext } from '../../services/authentication/Guard';
 
const UserPanelGrid = styled.div`
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-column-gap: 1rem;
    grid-row-gap: 4rem;
 
    @media ${device.lg} {
        grid-template-columns: auto auto auto;
    }
 
    @media ${device.md} {
        grid-template-columns: auto auto auto auto auto;
    }
 
    @media ${device.sm} {
        grid-template-columns: auto auto auto;
    }
 
    @media ${device.xs} {
        grid-template-columns: auto auto;
    }
`
 
const AppCard = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    cursor: pointer;
    width: 12rem;
 
    img{
        width: 100%;
        border-radius: 2rem;
        box-shadow: ${p => p.theme.out};
        transition: box-shadow .5s;
    }
 
    h5{
        text-align: center;
    }
 
    &:hover{
 
        img{
            box-shadow: ${p => p.theme.outFocus};
            transition: box-shadow .5s;
        }
    }
`
 
const MoreTools = styled(Card)`
    padding: 2rem;
    border-radius: .5rem;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: box-shadow .5s;
    
    &:hover{
        box-shadow: ${(p)=> p.theme.outFocus};
        transition: box-shadow .5s;
    }
`
 
const MoreToolsTop = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 2rem;
 
    img{
        width: 12rem;
    }
 
    h6{
        margin-bottom: 2rem;
    }
 
    @media ${device.lg} {
        grid-template-columns: 1fr;
    }
 
    @media ${device.md} {
        grid-template-columns: auto 1fr;
    }
 
    @media ${device.sm} {
        grid-template-columns: 1fr;
    }
`
 
const MoreToolsActions = styled.div`
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-top: 1px solid ${p=>p.theme.low};
    padding-top: 2rem;
`
 
const clients = require('../../config/index').config;
 
export default function USER_PANEL() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const lang = i18n.language ? i18n.language : 'en';
    const userId = store.getState().user?.user?.sub;
    const [mySubs, setMySubs] = useState([]);
    const { theme } = useContext(ThemeContext);
 
    const [hasCheckedAccess, setHasCheckedAccess] = useState(false);
    const planCategories = [    //All category names
        'dailyprofit',
        'lois',
        // 'bond',
    ]
    const [activeSubs, setActiveSubs] = useState([]);
    const [inactiveSubs, setInactiveSubs] = useState([]);
 
    const getAppImg = (app) => {
        if (app === 'AdSnap') return logo.lois[theme];
        else if (app === 'QuestAd') return logo.dailyprofit[theme];
        else return logo.lois[theme]; //TEMP LINE
 
    };
 
    const handleNav = (app) => {
        if (app === 'AdSnap') {
            window.location.assign(`${clients['lois']}/${lang}`);
        } else if ( app === 'QuestAd' ) {
            window.location.assign(`${clients['dailyprofit']}/${lang}`); //TEMP LINE
        } else if ( app === 'Tailormade' ) {
            window.location.assign(`${clients['lois']}/${lang}`); //TEMP LINE
        }
        else return;
    };
 
    const check_access = () => {
        checkAccess(planCategories)
        .then((res) => {
            let inactive = [];
            let active = [];
            for (const category in res) {
                if (Object.hasOwnProperty.call(res, category)) {
                    if ( res[category] ) active.push({name: category, text_name: matchCategoriesToText[category]})
                    else inactive.push({name: category, text_name: matchCategoriesToText[category]})
                }
            }
            setActiveSubs(active);
            setInactiveSubs(inactive);
            setHasCheckedAccess(true);
        }).catch((err) => {})
    }
 
    // const handleNav = (app) => {
    //     if (app === 'Adsnap') {
    //         window.location.assign(`${clients['lois']}/${lang}`);
    //     } else if ( app === 'Questad' ) {
    //         window.location.assign(`${clients['dailyprofit']}/${lang}`); //TEMP LINE
    //     } else if ( app === 'Tailormade' ) {
    //         window.location.assign(`${clients['lois']}/${lang}`); //TEMP LINE
    //     }
    //     else return;
    // };
 
    const planName =(category) =>{
        switch(category){
          case 'lois' : return 'Adsnap';   
          break;
          case 'dailyprofit': return "Questad" ;
          break;
          case 'bond': return 'Pocket Agency';     
          break;
          case 'monica': return "Mini Website";
          break;
          default: return ''
        }
    }
    useEffect(() => {
        get_my_subscriptions(userId)
            .then((data) => {
                if (!data?.data) {
                    setMySubs([]);
                } else {
                    console.log(data)
                    const updatedSubs = data?.data.map(category => ({
                        category: category.category,
                        hasActiveSubscription: category.tools.some(tool => tool.active)
                    }));
                    // Sort the updatedSubs array based on a predefined order
                    const orderedSubs = updatedSubs.sort((a, b) => {
                        const order = ['dailyprofit', 'bond', 'lois']; // Define your desired category order here
                        return order.indexOf(a.category) - order.indexOf(b.category);
                    });
                    setMySubs(orderedSubs);
                }
            })
            .catch((err) => {
                // Handle error
            });
    }, [userId]); // Adding userId as a dependency
 
 
    console.log('mySubs', mySubs)

    const chooseRedirect = (category) => {
        let url;
        // Perform different logic based on the category
        switch (category) {
            case 'lois':
                url = `${clients[category]}/${lang}/welcome`;
                break;
            case 'bond':
                url = `${clients['dailyprofit']}/${lang}`;
                break;
            case 'dailyprofit':
                url = `${clients[category]}/${lang}`;
                break;
        }
    
        // Return the generated URL
        return url;

    }

    const auth = useContext(AuthContext);
   
    const roles = auth?.user?.roles;
    const isScoutOrAbove = roles.includes('Scout') || roles.includes('Knight') || 
    roles.includes('Baron') || 
    roles.includes('King') || 
    roles.includes('Admin') ? true : false;
    console.log('documentationAccess',isScoutOrAbove, auth?.user?.roles );
    
    return (
        <PageSixty>
            <Helmet>
                <title>Doitforme | User panel</title>
            </Helmet>
            <PageAlwaysVisible>
                <PageTitle>
                    <h1>{t('Available tools')}</h1>
                </PageTitle>
                <UserPanelGrid>
                    {mySubs?.length ?
                        <>
                            {mySubs?.map((sub) =>(
                            sub?.hasActiveSubscription ?
                                <AppCard onClick={() => window.location.assign(chooseRedirect(sub?.category))}>
                                    <img src={logo[sub?.category][theme]} />
                                    <h5>{planName(sub?.category)}</h5>
                                </AppCard> : null
                            ))}
                        </>
                    :
                        null
                    }
                    {/* <AppCard onClick={() => window.location.assign(`${clients['whitecastle']}/${lang}` )} >
                        <img src={logo.whitecastle[theme]} />
                        <h5>{t("Market")}</h5>
                    </AppCard> */}
                    <AppCard>
                        <img src={logo?.syrax[theme]} onClick={() => window.location.assign(`${clients['syrax']}/${lang}` )}  />
                        <h5>{t("Taskmap")}</h5>
                    </AppCard>
                    {isScoutOrAbove ? 
                        <AppCard>
                            <img src={logo?.doitmentation[theme]} onClick={() => window.location.assign(`${clients['doitmentation']}/${lang}/` )}/>
                            <h5>{t("Documentation")}</h5>
                        </AppCard>
                    : null}
                    <AppCard>
                        <img src={logo.griphook[theme]} onClick={() => window.location.assign(`${clients['GRIPHOOK_FRONTEND_URL']}/${lang}/my-plans` )} />
                        <h5>{t("Subscriptions")}</h5>
                    </AppCard>
                    <AppCard>
                        <img src={logo.erised[theme]} onClick={() => navigate(`/${lang}/settings`)}/>
                        <h5>{t("Settings")}</h5>
                    </AppCard>
                  
                </UserPanelGrid>
            </PageAlwaysVisible>
                <PageAlwaysVisible>
                {(!mySubs?.some(sub => sub.category === 'dailyprofit' && !sub?.hasActiveSubscription)) &&
                (!mySubs?.some(sub => sub.category === 'lois' && !sub?.hasActiveSubscription)) ? (
                    <Section_Title text={t("More tools")} />
                ) : null}    
                                
                {!mySubs?.some(sub => sub.category === 'dailyprofit') || mySubs?.some(sub => sub.category === 'dailyprofit' && !sub?.hasActiveSubscription) ?
                            <MoreTools onClick={() => window.location.assign(`${clients['dailyprofit']}/${lang}`)} >
                                <MoreToolsTop>
                                    <img src={logo.dailyprofit[theme]} />
                                    <main>
                                        <h3>Questad</h3>
                                        <h6>{t("Automated social media Ads tool")}</h6>
                                        <p>{t("Streamline your advertising with our automated social media ads tool, designed to craft complex campaigns and strategies from simple questions. Perfect for freelancers and small businesses with tight budgets, it helps you reach potential leads effortlessly. Say goodbye to daunting marketing demands and hello to effective, budget-friendly promotions.")}</p>
                                    </main>
                                </MoreToolsTop>
                                <MoreToolsActions>
                                    <Focus_Plain noPadding text={t("Learn more")} onClick={() => window.location.assign(`${clients['dailyprofit']}/${lang}`)} iconRight="Next" />
                                </MoreToolsActions>
                            </MoreTools>
                        :
                            null
                        }
 
                    {!mySubs?.some(sub => sub.category === 'lois') || mySubs?.some(sub => sub.category === 'lois' && !sub?.hasActiveSubscription) ?
                            <MoreTools onClick={() => window.location.assign(`${clients['lois']}/${lang}`)} >
                                <MoreToolsTop>
                                    <img src={logo.lois[theme]} />
                                    <main>
                                        <h3>Adsnap</h3>
                                        <h6>{t("Meta reporting tool")}</h6>
                                        <p>{t("Enhance your client presentations with our digital marketing tool, designed for showcasing Facebook and Instagram campaign results. Craft beautiful, detailed reports with ease, using dynamic designs and KPIs that ensure your work stands out. Ideal for digital marketers that want to show compelling evidence of their success.")}</p>
                                    </main>
                                </MoreToolsTop>
                                <MoreToolsActions>
                                    <Focus_Plain noPadding text={t("Learn more")} onClick={() => window.location.assign(`${clients['lois']}/${lang}`)} iconRight="Next" />
                                </MoreToolsActions>
                            </MoreTools>
                        :
                            null
                        }
                </PageAlwaysVisible>
        </PageSixty>
    );
}